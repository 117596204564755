




import Vue from 'vue'

export default Vue.extend({
  name: 'NotFound',
  mounted() {
    this.$router.replace({ name: 'wizard' })
  }
})
